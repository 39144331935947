const tintLibrary = [
    { label: '90%', opacity: 1 - 90 / 100 },  // Very light tint
    { label: '80%', opacity: 1 - 80 / 100 },
    { label: '70%', opacity: 1 - 70 / 100 },
    { label: '60%', opacity: 1 - 60 / 100 },
    { label: '50%', opacity: 1 - 50 / 100 },
    { label: '40%', opacity: 1 - 40 / 100 },
    { label: '35%', opacity: 1 - 35 / 100 },
    { label: '30%', opacity: 1 - 30 / 100 },
    { label: '25%', opacity: 1 - 25 / 100 },
    { label: '20%', opacity: 1 - 20 / 100 },
    { label: '15%', opacity: 1 - 15 / 100 },  // Very dark tint
    { label: '0%', opacity: 1 - 0 / 100 }     // No tint
];

export default tintLibrary;
