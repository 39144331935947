import React, { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { useGLTF, Environment, OrbitControls } from '@react-three/drei';
import { Box } from '@mui/material';
import * as THREE from 'three';
import { EffectComposer, ToneMapping } from '@react-three/postprocessing';

const finishes = {
    gloss: {
        metalness: 0.7,
        roughness: 0.2,
        clearcoat: 1.0,
        clearcoatRoughness: 0.1,
        reflectivity: 1.0,
    },
    satin: {
        metalness: 0.5,
        roughness: 0.4,
        clearcoat: 0.6,
        clearcoatRoughness: 0.2,
        reflectivity: 0.8,
    },
    matte: {
        metalness: 0.2,
        roughness: 0.9,
        clearcoat: 0.0,
        reflectivity: 0.3,
    }
};

function Model({ selectedModel, selectedColor, selectedFinish, selectedTint }) {
    const modelRef = useRef();
    let modelPath;

    switch (selectedModel) {
        case 'tesla_cybertruck':
            modelPath = '/models/tesla_cybertruck.gltf';
            break;
        case 'bmw_m4':
            modelPath = '/models/bmw_m4.glb';
            break;
        case 'rangerover':
            modelPath = '/models/rangerover.glb';
            break;
        case 'porsche_911':
            modelPath = '/models/porsche_911.glb';
            break;
        default:
            modelPath = '/models/bmw_m4.glb';
    }

    const { scene } = useGLTF(modelPath, true);

    useEffect(() => {
        scene.traverse((child) => {
            if (child.isMesh && child.material) {
                const finish = finishes[selectedFinish];
                
                if (selectedModel === 'bmw_m4' && child.name.endsWith("Metallic_Car_Paint_-_EEVEE_0")) {
                    child.material.color.set(selectedColor);
                    if (finish) {
                        child.material.metalness = finish.metalness;
                        child.material.roughness = finish.roughness;
                        child.material.clearcoat = finish.clearcoat;
                        child.material.clearcoatRoughness = finish.clearcoatRoughness;
                        child.material.reflectivity = finish.reflectivity;
                        child.material.envMapIntensity = 0.3; 
                    }
                } else if (selectedModel === 'tesla_cybertruck' && (child.name.startsWith("DOOR_") || child.name.startsWith("BODY_"))) {
                    child.material.color.set(selectedColor);
                    if (finish) {
                        child.material.metalness = finish.metalness;
                        child.material.roughness = finish.roughness;
                        child.material.clearcoat = finish.clearcoat;
                        child.material.clearcoatRoughness = finish.clearcoatRoughness;
                        child.material.reflectivity = finish.reflectivity;
                        child.material.envMapIntensity = 0.3;
                    }
                } else if (selectedModel === 'rangerover' && child.name === "Plane009") {
                    child.material.color.set(selectedColor);
                    if (finish) {
                        child.material.metalness = finish.metalness;
                        child.material.roughness = finish.roughness;
                        child.material.clearcoat = finish.clearcoat;
                        child.material.clearcoatRoughness = finish.clearcoatRoughness;
                        child.material.reflectivity = finish.reflectivity;
                        child.material.envMapIntensity = 0.3;
                    }
                } else if (selectedModel === 'porsche_911' && child.name.includes("boot002")) {
                    child.material.color.set(selectedColor);
                    if (finish) {
                        child.material.metalness = finish.metalness;
                        child.material.roughness = finish.roughness;
                        child.material.clearcoat = finish.clearcoat;
                        child.material.clearcoatRoughness = finish.clearcoatRoughness;
                        child.material.reflectivity = finish.reflectivity;
                        child.material.envMapIntensity = 0.3;
                    }
                } else if (
                    (selectedModel === 'bmw_m4' && child.name.includes("Windshield_0")) || 
                    (selectedModel === 'tesla_cybertruck' && child.name.includes("BGLASS")) ||
                    (selectedModel === 'rangerover' && child.name === "Plane009_2") || 
                    (selectedModel === 'porsche_911' && child.name.includes("wind"))
                ) {
                    const tintColor = new THREE.Color(`rgb(0, 0, 0)`);
                    child.material.color.set(tintColor);
                    child.material.transparent = true;
                    child.material.opacity = selectedTint;
                    child.material.roughness = 0.1;
                    child.material.metalness = 0.0;
                }
            }
        });

        // Center the model based on its bounding box
        const box = new THREE.Box3().setFromObject(scene);
        const center = new THREE.Vector3();
        box.getCenter(center);
        scene.position.sub(center);  // Reposition the model so it's centered

        modelRef.current = scene;

    }, [scene, selectedColor, selectedFinish, selectedTint, selectedModel]);

    return (
        <primitive ref={modelRef} object={scene} scale={[2.5, 2.5, 2.5]} />
    );
}

export default function SimpleViewer({ selectedModel, selectedColor, selectedFinish, selectedTint }) {
    const fixedCameraPosition = new THREE.Vector3(10, 10, 10);
    const fixedCameraLookAt = new THREE.Vector3(0, 0, 0);

    const canvasKey = `${selectedModel}-${selectedColor}-${selectedFinish}-${selectedTint}`;

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Canvas
                    key={canvasKey}
                    camera={{ position: fixedCameraPosition.toArray(), fov: 35 }}
                    style={{ width: '100%', height: '100%', minHeight: '300px' }}
                    onCreated={({ gl, camera }) => {
                        camera.lookAt(fixedCameraLookAt);
                    }}
                >
                    {selectedModel && (
                        <>
                            <Model
                                selectedModel={selectedModel}
                                selectedColor={selectedColor}
                                selectedFinish={selectedFinish}
                                selectedTint={selectedTint}
                            />
                            <ambientLight intensity={0.5} />
                            <directionalLight intensity={1.5} position={[5, 10, 5]} />
                            <directionalLight intensity={1.5} position={[-5, 10, -5]} />

                            <Environment 
                                files="/models/overpass.hdr" 
                                background={false} 
                                rotation={[0, 0, 0]} 
                                fixed={true}
                            />
                            <EffectComposer>
                                <ToneMapping exposure={0.5} />
                            </EffectComposer>
                            <OrbitControls 
                                enableZoom={false} 
                                enablePan={true} 
                                maxPolarAngle={Math.PI / 2} 
                                minPolarAngle={Math.PI / 3} 
                                target={[0, 0, 0]}  // Update target to [0, 0, 0] to match the centered model
                            />
                        </>
                    )}
                </Canvas>
            </Box>
        </Box>
    );
}
