import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Container, Box, Avatar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';  // Access the AuthContext to get userProfile
import { signOut } from 'firebase/auth';
import { auth } from './firebase';

const NavigationBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { currentUser, userProfile } = useAuth();  // Get currentUser and userProfile
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const getInitial = () => {
    if (currentUser && currentUser.email) {
      return currentUser.email.charAt(0).toUpperCase();
    }
    return '';
  };

  // Define pages for users who are not "Paid"
  const pages = [
    { name: 'Home', link: '/' },
    { name: 'Features', link: '/features' },
    { name: 'Pricing', link: '/pricing' },
    { name: 'Contact', link: '/contact' },
    // Only show the "Demo" link if the user is not "Paid"
    ...(userProfile?.role !== 'Paid' ? [{ name: 'Demo', link: '/demo' }] : []),
  ];

  return (
    <AppBar position="static" color="default">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {/* Logo */}
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              fontWeight: 'bold',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <DirectionsCarIcon sx={{ mr: 1 }} />
            VisionStudio
          </Typography>

          {/* Mobile Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu} component={Link} to={page.link}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              {/* Show Configurator link only if user has a "Paid" role */}
              {userProfile?.role === 'Paid' && (
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/configurator">
                  <Typography textAlign="center">Configurator</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>

          {/* Desktop Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button key={page.name} component={Link} to={page.link} sx={{ my: 2, color: 'inherit' }}>
                {page.name}
              </Button>
            ))}
            {/* Show Configurator link only if user has a "Paid" role */}
            {userProfile?.role === 'Paid' && (
              <Button component={Link} to="/configurator" sx={{ my: 2, color: 'inherit' }}>
                Configurator
              </Button>
            )}
          </Box>

          {/* User Auth Options */}
          <Box sx={{ flexGrow: 0 }}>
            {currentUser ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{ bgcolor: 'primary.main' }}>{getInitial()}</Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate('/account');
                    }}
                  >
                    <Typography textAlign="center">Account Settings</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      handleSignOut();
                    }}
                  >
                    <Typography textAlign="center">Sign Out</Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button component={Link} to="/login" color="inherit" sx={{ mr: 2 }}>
                  Login
                </Button>
                <Button component={Link} to="/signup" variant="contained" color="primary">
                  Sign Up
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavigationBar;
