import React from 'react';
import { Typography, Box, Container, Grid, Button } from '@mui/material';
import NavigationBar from './NavigationBar';  // Assuming you have this component
import { Link } from 'react-router-dom';

const ContactPage = () => {
  return (
    <Box sx={{ backgroundColor: '#f9f9f9' }}>
      {/* Navigation Bar */}
      <NavigationBar />

      {/* Main Content */}
      <Box sx={{ py: { xs: 6, md: 12 } }}>
        <Container>
          {/* Header Section */}
          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
            Get in Touch with Us
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', mb: 6 }}>
            Have questions about VisionStudio or need support? We're here to help! Reach out to our team for custom options, feature requests, technical support, or any inquiries you may have.
          </Typography>

          {/* Email Section */}
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
                Contact Our Support Team
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center', mb: 4 }}>
                Feel free to reach out to us via email at <a href="mailto:support@reignovation.com" style={{ color: '#1976d2', fontWeight: 'bold' }}>support@reignovation.com</a>. We are committed to providing prompt responses and excellent customer service.
              </Typography>
            </Grid>
          </Grid>

          {/* Section: Why Reach Out */}
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
            Why Reach Out to Us?
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>Custom Options and Colors:</strong> Looking for more customization options or unique color schemes that fit your brand or customer's needs? Contact us, and we'll work together to find the perfect solution.
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>Feature Requests:</strong> Have ideas or specific features you'd like to see in VisionStudio? We're always evolving and value your input. Let us know how we can make our tool even more powerful for your business.
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>Technical Support:</strong> Not seeing the configurator tab, or encountering any issues with the configurator? Our support team is available to assist you with troubleshooting and ensure that VisionStudio runs smoothly. After your payment has been submitted through stripe, the configurator tab may take up to 24 hours to show.
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>Payments:</strong> All payments are processed through Stripe. If you have any questions or concerns regarding payments, please reach out. We are here to help you with any payment-related issues.
              </Typography>
            </Grid>
          </Grid>

          {/* Section: More Reasons to Contact Us */}
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mt: 6, mb: 4 }}>
            More Reasons to Contact VisionStudio
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>Partnership Opportunities:</strong> Interested in partnering with us? We are always open to exploring collaboration opportunities that can help grow both of our businesses. Contact us to discuss how we can work together.
              </Typography>
            </Grid>
          </Grid>

          {/* Contact Action Button */}
          <Grid container justifyContent="center" sx={{ mt: 6 }}>
            <Button
              href="mailto:support@reignovation.com"
              variant="contained"
              color="primary"
              size="large"
              sx={{ borderRadius: '8px', px: 5 }}
            >
              Email Us
            </Button>
          </Grid>

          {/* Closing Text */}
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 4 }}>
            We’re here to ensure you get the most out of VisionStudio. Whether you're looking for support or ways to enhance your workflow, feel free to contact us.
          </Typography>
        </Container>
      </Box>

      {/* Footer Section */}
      <Box sx={{ backgroundColor: '#0d47a1', color: '#fff', py: 6 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                About Us
              </Typography>
              <Typography variant="body2">
              Reignovation is a software consulting company specializing in creating innovative, custom solutions for businesses across industries. We help companies streamline operations and elevate their customer experiences with cutting-edge technology and tools.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Quick Links
              </Typography>
              <Typography variant="body2">
                <Link to="/features" style={{ color: '#fff', textDecoration: 'none' }}>
                  Features
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/pricing" style={{ color: '#fff', textDecoration: 'none' }}>
                  Pricing
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>
                  Contact Us
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/demo" style={{ color: '#fff', textDecoration: 'none' }}>
                  Try the Demo
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email: <a href="mailto:support@reignovation.com" style={{ color: '#fff' }}>support@reignovation.com</a>
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 4 }}>
            © {new Date().getFullYear()} Reignovation. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default ContactPage;
