import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import your authentication context
import NavigationBar from './NavigationBar'; // Assuming you already have this

const PricingPage = () => {
  const { currentUser } = useAuth(); // Extract currentUser from the useAuth hook
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // Dialog state for plan choice

  const handleSignup = () => {
    if (!currentUser) {
      // Redirect to login/signup if the user is not logged in
      navigate('/login'); // Or navigate('/signup') depending on your flow
    } else {
      // Show plan selection dialog if the user is logged in
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMonthly = () => {
    window.location.href = 'https://buy.stripe.com/4gw3ds2p30Lvdji145'; // Redirect to Stripe monthly payment link
  };

  const handleYearly = () => {
    window.location.href = 'https://buy.stripe.com/fZe7tI0gVeClgvudQQ'; // Redirect to Stripe yearly payment link
  };

  return (
    <>
      <NavigationBar />

      <Box sx={{ py: 5, backgroundColor: '#f5f5f5' }}>
        <Container>
          <Typography variant="h3" align="center" gutterBottom>
            Our Pricing Plans
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {/* Free Plan */}
            <Grid item xs={12} md={4}>
              <Card
                elevation={3}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  backgroundColor: '#fafafa',
                }}
              >
                <CardContent>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Free
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    $0/month
                  </Typography>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li>Limited car models</li>
                    <li>Basic color options</li>
                    <li>Experience core features</li>
                    <li>Full 3D viewer (limited)</li>
                  </ul>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', mb: 2 }}>
                  <Button variant="outlined" color="primary" component={Link} to="/demo">
                    Try the Demo
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            {/* Mid-Tier Plan - Standard */}
            <Grid item xs={12} md={4}>
              <Card
                elevation={5}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  backgroundColor: '#e3f2fd',
                  border: '3px solid #0d47a1',
                  transform: 'scale(1.05)',
                }}
              >
                <CardContent>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Standard
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    $2.99/month or $29/year
                  </Typography>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li>Access to all car models</li>
                    <li>Full range of colors and tints</li>
                    <li>High-resolution 3D views</li>
                    <li>No watermarks</li>
                  </ul>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', mb: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleSignup}>
                    Sign Up Now
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            {/* Custom Plan */}
            <Grid item xs={12} md={4}>
              <Card
                elevation={3}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  backgroundColor: '#f3e5f5',
                  border: '2px solid #9c27b0',
                }}
              >
                <CardContent>
                  <Typography variant="h5" color="secondary" gutterBottom>
                    Custom
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Contact for Pricing
                  </Typography>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li>Custom car models</li>
                    <li>Exclusive color and tint options</li>
                    <li>One-on-one support</li>
                    <li>Priority feature requests</li>
                  </ul>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', mb: 2 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    href="mailto:support@reignovation.com"
                  >
                    Contact Us
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>

          {/* Dialog for Monthly/Yearly Plan Selection */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Select Your Plan</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please select whether you'd like to sign up for the monthly or yearly subscription.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleMonthly} color="primary">
                Monthly ($2.99)
              </Button>
              <Button onClick={handleYearly} color="primary">
                Yearly ($29)
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>

      {/* Footer Section */}
      <Box sx={{ backgroundColor: '#0d47a1', color: '#fff', py: 6 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                About Us
              </Typography>
              <Typography variant="body2">
                Reignovation is a software consulting company specializing in
                creating innovative, custom solutions for businesses across
                industries. We help companies streamline operations and elevate
                their customer experiences with cutting-edge technology and
                tools.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Quick Links
              </Typography>
              <Typography variant="body2">
                <Link to="/features" style={{ color: '#fff', textDecoration: 'none' }}>
                  Features
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/pricing" style={{ color: '#fff', textDecoration: 'none' }}>
                  Pricing
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>
                  Contact Us
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/demo" style={{ color: '#fff', textDecoration: 'none' }}>
                  Try the Demo
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email:{' '}
                <a href="mailto:support@reignovation.com" style={{ color: '#fff' }}>
                  support@reignovation.com
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 4 }}>
            © {new Date().getFullYear()} Reignovation. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default PricingPage;
