import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
  const { currentUser, userProfile } = useAuth();  // Access userProfile
  const location = useLocation();

  // Redirect to login if user is not authenticated
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If the user tries to access "/configurator" and their role is not "Paid", redirect to pricing
  if (location.pathname === '/configurator' && userProfile?.role !== 'Paid') {
    return <Navigate to="/pricing" />;
  }

  return children; // Render the protected component if access is allowed
};

export default ProtectedRoute;
