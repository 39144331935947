import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, TextField, Button, Paper, Divider } from '@mui/material';
import { auth, db } from './firebase'; // Import db from firebase.js
import { useAuth } from './AuthContext';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import NavigationBar from './NavigationBar'; // Ensure this is imported

const AccountPage = () => {
  const { currentUser } = useAuth(); // Extract currentUser from useAuth hook
  const [newPassword, setNewPassword] = useState('');
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState(''); // New state to hold the user's role
  const navigate = useNavigate();

  // Fetch user's role from Firestore on component mount
  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        try {
          const userDoc = doc(db, 'users', currentUser.uid);
          const userSnapshot = await getDoc(userDoc);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setRole(userData.role || 'Free Tier (Default)'); // Set role or fallback to default
          }
        } catch (error) {
          console.error('Error fetching user role: ', error);
        }
      }
    };

    fetchUserRole();
  }, [currentUser]);

  // Function to reauthenticate user before updating password
  const reauthenticateUser = async () => {
    const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
    try {
      await reauthenticateWithCredential(auth.currentUser, credential);
      return true;
    } catch (error) {
      setError('Re-authentication failed. Please check your current password.');
      return false;
    }
  };

  const handleChangePassword = async () => {
    setError(''); // Clear previous errors
    if (newPassword) {
      const isReauthenticated = await reauthenticateUser();
      if (isReauthenticated) {
        try {
          await updatePassword(auth.currentUser, newPassword);
          setPasswordUpdated(true);
        } catch (error) {
          setError('Error updating password. Please try again.');
          console.error('Error updating password:', error);
        }
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <Box>
      <NavigationBar /> {/* Ensure Navbar is present */}
      <Container maxWidth="md" sx={{ mt: 8 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            Account Settings
          </Typography>

          {/* Email Display */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Email: {currentUser ? currentUser.email : 'Loading...'}
            </Typography>
          </Box>

          {/* Change Password Section */}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Change Password</Typography>
            <TextField
              label="Current Password"
              type="password"
              variant="outlined"
              fullWidth
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="New Password"
              type="password"
              variant="outlined"
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleChangePassword}>
              Update Password
            </Button>
            {passwordUpdated && (
              <Typography variant="body2" color="success.main" sx={{ mt: 2 }}>
                Password updated successfully!
              </Typography>
            )}
            {error && (
              <Typography variant="body2" color="error.main" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </Box>

          {/* Subscription Tier Section */}
          <Divider sx={{ my: 4 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography variant="h6">Subscription Tier</Typography>
              <Typography variant="body1" color="text.secondary">
                {role || 'Loading...'} {/* Display the user's role */}
              </Typography>
            </Box>
            {currentUser && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate('/pricing')}
              >
                Upgrade Now
              </Button>
            )}
          </Box>

          {/* Sign Out Section */}
          <Divider sx={{ my: 4 }} />
          <Box>
            <Button variant="outlined" color="error" onClick={handleSignOut}>
              Sign Out
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default AccountPage;
