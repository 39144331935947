import React from 'react';
import { Typography, Box, Container, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Feature1Image from './assets/feature1.png';
import Feature2Image from './assets/feature2.png';
import Feature3Image from './assets/feature3.png';
import NavigationBar from './NavigationBar';

const FeaturesPage = () => {
  return (
    <Box sx={{ backgroundColor: '#f9f9f9' }}>
      <NavigationBar />

      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          backgroundImage: 'url(/assets/hero-image.png)', // Replace with your hero image path
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#fff',
          py: { xs: 6, md: 12 },
          textAlign: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          },
        }}
      >
        <Container sx={{ position: 'relative', zIndex: 2 }}>
          <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2, color: '#fff' }}>
            Transform Your Car Wrapping Business with VisionStudio
          </Typography>
          <Typography variant="h5" sx={{ mb: 4, color: '#f1f1f1' }}>
            Enhance customer engagement and streamline the design process with our interactive, real-time configurator.
          </Typography>
        </Container>
      </Box>

      {/* Key Features Section */}
      <Container sx={{ py: { xs: 6, md: 12 } }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 6 }}>
          Powerful Features of VisionStudio
        </Typography>
        <Grid container spacing={6}>
          {[
            {
              image: Feature1Image,
              title: 'Wide Range of Color Selections',
              description:
                'With VisionStudio, you can offer your customers a wide selection of vinyl wrap colors and tints. From metallic finishes to matte tones, users can experiment with their preferred colors and immediately visualize them on their vehicle.',
            },
            {
              image: Feature2Image,
              title: 'Real-Time 3D Visualization',
              description:
                'Our cutting-edge 3D visualization allows your customers to see their vehicle in stunning detail, updated in real-time as they make selections. No more waiting or imagining—changes are reflected instantly for a seamless customization experience.',
            },
            {
              image: Feature3Image,
              title: 'Cross-Device Compatibility',
              description:
                'VisionStudio is fully optimized for all devices, whether your customers are using a desktop, tablet, or mobile phone. This means they can explore designs and make customizations anytime, anywhere.',
            },
          ].map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  boxShadow: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardMedia
                  component="img"
                  image={feature.image}
                  alt={feature.title}
                  sx={{
                    height: 200,
                    objectFit: 'contain',
                    backgroundColor: '#f0f0f0',
                  }}
                />
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Why Choose VisionStudio Section */}
      <Container sx={{ py: { xs: 6, md: 12 } }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 6 }}>
          Why VisionStudio is a Game-Changer for Car Wrapping Businesses
        </Typography>
        <Grid container spacing={4}>
          {[
            {
              title: 'Increase Customer Satisfaction',
              description:
                'VisionStudio provides a highly engaging experience by giving customers full control over their car’s customization. The ability to instantly visualize changes in 3D makes decision-making easier and boosts customer satisfaction.',
            },
            {
              title: 'Save Time and Reduce Costs',
              description:
                'No more back-and-forth with customers, no need for physical samples. VisionStudio enables customers to make choices quickly and confidently online, reducing the need for costly showroom visits and long decision cycles.',
            },
            {
              title: 'Boost Sales with Interactive Tools',
              description:
                'By offering a state-of-the-art tool that allows for interactive car customization, your customers are more likely to engage, stay longer on your site, and convert their interest into a purchase. This directly leads to increased sales.',
            },
            {
              title: 'Seamless Integration with Your Website',
              description:
                'VisionStudio can be easily integrated into your existing website, offering your customers a seamless transition from browsing to customizing their vehicle’s wrap.',
            },
          ].map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, mb: 2 }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Benefits Section */}
      <Container sx={{ py: { xs: 6, md: 12 } }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 6 }}>
          How VisionStudio Benefits Your Business
        </Typography>
        <Grid container spacing={4}>
          {[
            {
              title: 'Maximize Engagement',
              description:
                'Our configurator provides an immersive, interactive experience that keeps customers engaged for longer, increasing the likelihood of a sale.',
            },
            {
              title: 'Real-Time Updates',
              description:
                'As customers select their preferred colors, tints, or wraps, they see real-time updates reflected on a 3D model of their car, allowing them to confidently finalize their decision.',
            },
            {
              title: 'Reduce Sales Cycle',
              description:
                'With real-time visualizations and instant customization options, customers can make quick decisions without the need for repeated visits, leading to a shorter sales cycle.',
            },
            {
              title: 'Enhance Your Brand',
              description:
                'By offering an advanced, interactive tool like VisionStudio, your business positions itself as an innovative leader in the car wrapping industry.',
            },
          ].map((benefit, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, mb: 2 }}>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {benefit.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Call to Action */}
      <Box sx={{ backgroundColor: '#1976d2', color: '#fff', py: { xs: 6, md: 12 }, textAlign: 'center' }}>
        <Container>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
            Ready to Elevate Your Car Wrapping Business?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Contact us today and see how VisionStudio can transform your business.
          </Typography>
          <Button
            component={Link}
            to="/contact"
            variant="contained"
            color="secondary"
            size="large"
            sx={{ borderRadius: '8px', px: 5, mr: 2 }}
          >
            Contact Us
          </Button>
        </Container>
      </Box>

      {/* Footer Section */}
      <Box sx={{ backgroundColor: '#0d47a1', color: '#fff', py: 6 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                About Us
              </Typography>
              <Typography variant="body2">
              Reignovation is a software consulting company specializing in creating innovative, custom solutions for businesses across industries. We help companies streamline operations and elevate their customer experiences with cutting-edge technology and tools.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Quick Links
              </Typography>
              <Typography variant="body2">
                <Link to="/features" style={{ color: '#fff', textDecoration: 'none' }}>
                  Features
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/pricing" style={{ color: '#fff', textDecoration: 'none' }}>
                  Pricing
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>
                  Contact Us
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/demo" style={{ color: '#fff', textDecoration: 'none' }}>
                  Try the Demo
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email: <a href="mailto:support@reignovation.com" style={{ color: '#fff' }}>support@reignovation.com</a>
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 4 }}>
            © {new Date().getFullYear()} Reignovation. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default FeaturesPage;
