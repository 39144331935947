import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import PricingPage from './components/PricingPage';
import Configurator from './components/Configurator';
import TryItYourself from './components/TryItYourself';
import FeaturesPage from './components/FeaturesPage';
import ContactPage from './components/ContactPage';
import Login from './components/Login';
import Signup from './components/Signup';
import AccountPage from './components/AccountPage';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './components/AuthContext';  // Make sure this is correctly imported

function App() {
  return (
    <AuthProvider>  {/* Ensure the AuthProvider wraps all components */}
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route
            path="/configurator"
            element={
              <ProtectedRoute>
                <Configurator />
              </ProtectedRoute>
            }
          />
          <Route path="/demo" element={<ProtectedRoute><TryItYourself /></ProtectedRoute>} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/account" element={<AccountPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
