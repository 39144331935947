import React, { useState } from 'react';
import { auth, db } from './firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography, Paper} from '@mui/material';
import NavigationBar from './NavigationBar';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const provider = new GoogleAuthProvider();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError('Invalid email or password. Please try again.');
      console.error("Error logging in: ", error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user exists in Firestore, if not create a new document
      const userDoc = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userDoc);

      if (!docSnap.exists()) {
        // If the user is new, create a new document in Firestore
        await setDoc(userDoc, {
          firstName: user.displayName.split(' ')[0],
          lastName: user.displayName.split(' ')[1],
          email: user.email,
          role: 'User',
        });
      }

      navigate('/');
    } catch (error) {
      setError('Google Sign-In failed. Please try again.');
      console.error('Error with Google Sign-In: ', error);
    }
  };

  return (
    <>
      <NavigationBar /> {/* Add navigation bar here */}
      <Container maxWidth="sm">
        <Box sx={{ mt: 8 }}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
              Login to Your Account
            </Typography>
            {error && (
              <Typography variant="body2" color="error" sx={{ mb: 2, textAlign: 'center' }}>
                {error}
              </Typography>
            )}
            <form onSubmit={handleLogin}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 3 }}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 3 }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ py: 1.5, mb: 3 }}
              >
                Login
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleGoogleSignIn}
              >
                Sign in with Google
              </Button>
            </form>

            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              Don't have an account?{' '}
              <Button component={Link} to="/signup" color="secondary" sx={{ textTransform: 'none' }}>
                Sign Up
              </Button>

            </Typography>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Login;
