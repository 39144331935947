// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBNoyG5xT8uBBB-SJ9DhfW-tVE5D7qRJ6U",
  authDomain: "vae-website-show.firebaseapp.com",
  projectId: "vae-website-show",
  storageBucket: "vae-website-show.appspot.com",
  messagingSenderId: "755340385876",
  appId: "1:755340385876:web:0ba24ea2e918ea6515d7c1",
  measurementId: "G-YS7DD8DPMB"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };