// HomePage.js
import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import {
  PersonAddAlt1,
  Speed,
  StarBorder,
  Insights,
  Language,
  Visibility,
  Devices,
  TouchApp,
} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
// import HeroImage from './assets/hero-image.png';
import Feature1Image from './assets/feature1.png';
import Feature2Image from './assets/feature2.png';
import Feature3Image from './assets/feature3.png';
import NavigationBar from './NavigationBar';
import emailjs from 'emailjs-com';


const HomePage = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    if (!email) {
      alert('Please enter your email');
      return;
    }

    const templateParams = {
      user_email: email, // Email entered by the user
    };

    emailjs
      .send(
        'service_67muqpb',   // EmailJS service ID
        'template_jffh3eh',  // EmailJS template ID
        templateParams,
        'YJyD3ZgEoRxdV8FRk' // Your public API key from EmailJS
      )
      .then(
        (response) => {
          alert('Thank you for signing up! We will be in touch.');
          setEmail(''); // Clear the email input
        },
        (error) => {
          console.error('FAILED...', error);
          alert('An error occurred. Please try again later.');
        }
      );
  };

  const ContactButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        href="mailto:support@reignovation.com?subject=Contact Sales&body=Hello, I would like to inquire about..."
      >
        Contact Sales
      </Button>
    );
  };

  

  return (
    <Box sx={{ backgroundColor: '#f9f9f9' }}>
      <NavigationBar />

      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#fff',
          py: { xs: 6, md: 12 },
          textAlign: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          },
        }}
      >
        <Container
          maxWidth="md"
          sx={{ position: 'relative', zIndex: 2 }}
        >
          <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2, color: '#fff' }}>
            Transform Your Car Wrapping Business
          </Typography>
          <Typography variant="h5" sx={{ mb: 4, color: '#f1f1f1' }}>
            Engage customers like never before with our interactive online car configurator.
          </Typography>
          <Button
            component={Link}
            to="/demo"
            variant="contained"
            color="primary"
            size="large"
            sx={{ borderRadius: '8px', px: 5 }}
          >
            Try the Demo
          </Button>
        </Container>
      </Box>

      {/* About the Tool Section */}
      <Container sx={{ py: { xs: 6, md: 12 } }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', textAlign: 'center', mb: 6 }}
        >
          About Our Configurator
        </Typography>
        <Grid container spacing={4}>
          {[
            {
              title: 'Fully Interactive and Web-Based',
              description:
                'Our car wrap configurator is built as a web-based application, allowing users to select from a wide variety of colors and tints for car wraps. The tool provides real-time updates as users experiment with different looks for their vehicles.',
              icon: <Language sx={{ fontSize: 60, color: 'primary.main' }} />, // Represents the web-based and interactive aspect
            },
            {
              title: 'Real-Time Visualization and Customization',
              description:
                'This tool enables users to instantly see changes as they select different vinyl wraps, colors, and tints. No need to rely on physical samples, as customers can visualize how the vehicle will look with each customization in real-time.',
              icon: <Visibility sx={{ fontSize: 60, color: 'primary.main' }} />, // Represents the real-time visualization
            },
            {
              title: 'Accessible Across Devices',
              description:
                'Our configurator is designed for compatibility with all device types, including desktop, tablet, and smartphone. It’s fully functional on any browser, offering a smooth and responsive experience whether you’re using a mobile device or a computer.',
              icon: <Devices sx={{ fontSize: 60, color: 'primary.main' }} />, // Represents cross-device accessibility
            },
            {
              title: 'User-Friendly Design',
              description:
                'The tool is easy to use, requiring no additional downloads or installations. It guides users through a simple process of customizing vehicle appearances, making it easy for them to explore various designs and select their preferred wrap color.',
              icon: <TouchApp sx={{ fontSize: 60, color: 'primary.main' }} />, // Represents ease of use and intuitive design
            },
          ].map((feature, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  {feature.icon}
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, mb: 2 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>



      {/* Key Features Section */}
      <Container sx={{ py: { xs: 6, md: 12 } }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', textAlign: 'center', mb: 8 }}
        >
          Key Features
        </Typography>
        <Grid container spacing={6}>
          {[
            {
              image: Feature1Image, // Image showing color and tint selection
              title: 'Select from a Wide Range of Colors and Tints',
              description:
                'Give your customers a wide selection of vinyl wrap colors and tints. Our configurator allows them to visualize their vehicle with different color schemes in just a few clicks.',
              alt: 'Color and Tint Selection',
            },
            {
              image: Feature2Image, // Image showing real-time customization
              title: 'Real-Time Customization',
              description:
                'Instantly see how your customers’ chosen wraps, tints, and colors will look on their vehicle. Customize and visualize in real-time, providing an interactive experience.',
              alt: 'Real-Time Customization',
            },
            {
              image: Feature3Image, // Image showing mobile devices with the configurator
              title: 'Mobile-Friendly Design',
              description:
                'Our tool is designed to work flawlessly across all devices. Whether on desktop, tablet, or smartphone, customers can engage with the configurator wherever they are.',
              alt: 'Mobile-Friendly Access',
            },
          ].map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  boxShadow: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardMedia
                  component="img"
                  image={feature.image}
                  alt={feature.alt}
                  sx={{
                    height: 200,
                    objectFit: 'contain',
                    backgroundColor: '#f0f0f0',
                  }}
                />
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>


      {/* How It Works Section */}
      <Box sx={{ backgroundColor: '#fff', py: { xs: 6, md: 12 } }}>
        <Container>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', textAlign: 'center', mb: 8 }}
          >
            How It Works
          </Typography>
          <Grid container spacing={6}>
            {[
              {
                number: '1',
                title: 'Explore Color & Tint Options',
                description: 'Let your customers choose from a variety of car wrap colors and tints to visualize what their vehicle will look like instantly.',
              },
              {
                number: '2',
                title: 'Interactive Customization',
                description: 'Engage your customers by allowing them to customize the look of their vehicle in real-time. They can see their desired colors and tints applied directly on the car model.',
              },
              {
                number: '3',
                title: 'Simplify Decision-Making',
                description: 'Help your customers make faster, more informed decisions by eliminating the guesswork. No more physical swatches or showroom visits—everything is visualized digitally.',
              },
              {
                number: '4',
                title: 'Increase Customer Engagement & Sales',
                description: 'By offering a cutting-edge, immersive experience, engage longer, and convert their interest into sales.',
              },

            ].map((step, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="h1"
                    color="primary"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {step.number}
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    {step.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {step.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Benefits Section */}
      <Container sx={{ py: { xs: 6, md: 12 } }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', textAlign: 'center', mb: 6 }}
        >
          Benefits for Your Business
        </Typography>
        <Grid container spacing={4}>
          {[
            {
              title: 'Enhance Customer Experience',
              description:
                'Empower your customers with a dynamic tool that allows them to visualize their vehicle in various colors instantly, eliminating the guesswork and making their decision-making process more enjoyable and informed.',
              icon: <PersonAddAlt1 sx={{ fontSize: 60, color: 'primary.main' }} />,
            },
            {
              title: 'Simplify Decision-Making',
              description:
                'By offering a real-time visual representation of wrap options, you minimize the need for physical swatches or showroom visits, making it easier for customers to choose the perfect wrap quickly and confidently.',
              icon: <Speed sx={{ fontSize: 60, color: 'primary.main' }} />,
            },
            {
              title: 'Stand Out from Competitors',
              description:
                'Stand out in the car wrapping industry by offering a cutting-edge, interactive digital experience, setting your business apart from competitors still relying on traditional methods.',
              icon: <StarBorder sx={{ fontSize: 60, color: 'primary.main' }} />,
            },
            {
              title: 'Reduce Sales Barriers',
              description:
                'Streamline the consultation process by allowing customers to explore wrap options online, reducing the back-and-forth and helping them reach a decision faster, which leads to quicker sales and happier clients.',
              icon: <Insights sx={{ fontSize: 60, color: 'primary.main' }} />,
            },
          ].map((benefit, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  {benefit.icon}
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, mb: 2 }}>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {benefit.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>


      {/* Early Access Section */}
      <Box sx={{ backgroundColor: '#f9f9f9', py: { xs: 6, md: 12 } }}>
        <Container maxWidth="md">
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}
          >
            Join Our Early Access Program
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', mb: 3 }}
          >
            Be a part of shaping the future of car wrapping! Sign up now to join our exclusive Early Access Program and help us build tailored versions of our configurator based on your business needs.
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', mb: 6 }}
          >
            Direct input on new features, priority support, and special discounts await!
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8}>
              <TextField
                label="Enter your email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                sx={{ height: '100%', borderRadius: '8px' }}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>

          <Typography
            variant="caption"
            display="block"
            gutterBottom
            sx={{ textAlign: 'center', mt: 3 }}
          >
            We respect your privacy. Your email will only be used to contact you about the Early Access Program.
          </Typography>
        </Container>
      </Box>


      {/* FAQs Section */}
      <Container sx={{ py: { xs: 6, md: 12 } }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', textAlign: 'center', mb: 6 }}
        >
          Frequently Asked Questions
        </Typography>
        {[
          {
            question: 'What customization options are available in the configurator?',
            answer:
              'Our configurator allows customers to choose from a variety of car wrap colors and tints. Customers can see real-time visual updates of how these changes will look on the vehicle.',
          },
          {
            question: 'Is the configurator mobile-friendly?',
            answer:
              'Yes, our configurator is designed to work seamlessly on all devices, including desktops, tablets, and smartphones. Customers can interact with it from anywhere.',
          },
          {
            question: 'How does the configurator engage customers?',
            answer:
              'The configurator provides an interactive experience where customers can instantly visualize their desired car wraps and tints, making the process more engaging and helping them make decisions faster.',
          },
          {
            question: 'How can using the configurator help boost sales?',
            answer:
              'By allowing customers to customize their vehicle’s appearance and visualize the changes in real-time, you reduce decision-making barriers, which leads to faster purchases and higher customer satisfaction.',
          },
        ].map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>


      {/* Call to Action Section */}
      <Box
        sx={{
          backgroundColor: '#1976d2',
          color: '#fff',
          py: { xs: 6, md: 12 },
          textAlign: 'center',
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
            Ready to Elevate Your Business?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Get started today and see the difference our configurator can make.
          </Typography>
          <Button
            component={Link}
            to="/signup"
            variant="contained"
            color="secondary"
            size="large"
            sx={{ borderRadius: '8px', px: 5, mr: 2 }}
          >
            Sign Up Now
          </Button>
              <ContactButton />
        </Container>
      </Box>

      {/* Blog/Resources Section
      <Container sx={{ py: { xs: 6, md: 12 } }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', textAlign: 'center', mb: 6 }}
        >
          Latest from Our Blog
        </Typography>
        <Grid container spacing={6}>
          {[
            {
              title: '5 Ways to Boost Your Car Wrapping Business',
              excerpt:
                'Discover effective strategies to increase sales and customer satisfaction in the car wrapping industry.',
              link: '/blog/boost-business',
            },
            {
              title: 'The Future of Car Customization',
              excerpt:
                'Learn how technology is revolutionizing the way customers personalize their vehicles.',
              link: '/blog/future-customization',
            },
            {
              title: 'How Our Configurator Increases Sales',
              excerpt:
                'A deep dive into how our tool can positively impact your bottom line.',
              link: '/blog/increase-sales',
            },
          ].map((post, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ boxShadow: 3, height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {post.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 4 }}>
                    {post.excerpt}
                  </Typography>
                  <Button
                    component={Link}
                    to={post.link}
                    variant="outlined"
                    color="primary"
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container> */}

      {/* Footer Section */}
      <Box sx={{ backgroundColor: '#0d47a1', color: '#fff', py: 6 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                About Us
              </Typography>
              <Typography variant="body2">
              Reignovation is a software consulting company specializing in creating innovative, custom solutions for businesses across industries. We help companies streamline operations and elevate their customer experiences with cutting-edge technology and tools.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Quick Links
              </Typography>
              <Typography variant="body2">
                <Link to="/features" style={{ color: '#fff', textDecoration: 'none' }}>
                  Features
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/pricing" style={{ color: '#fff', textDecoration: 'none' }}>
                  Pricing
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>
                  Contact Us
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/demo" style={{ color: '#fff', textDecoration: 'none' }}>
                  Try the Demo
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email: <a href="mailto:support@reignovation.com" style={{ color: '#fff' }}>support@reignovation.com</a>
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 4 }}>
            © {new Date().getFullYear()} Reignovation. All rights reserved.
          </Typography>
        </Container>
      </Box>

    </Box>
  );
};

export default HomePage;
